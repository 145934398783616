import React, { useState } from 'react';
import { Link, graphql } from 'gatsby'
import { useFlexSearch } from 'react-use-flexsearch';
import { GatsbyImage } from "gatsby-plugin-image"
import Header from '../components/header'
import Footer from '../components/footer'
import Seo from '../components/seo'

const SearchResults = ({data}) => {

    const entry = data.markdownRemark
    //console.log('data: ', data)

    const {search} = typeof window !== 'undefined' ? window.location : '';
    const query = new URLSearchParams(search).get('s');
    const [searchQuery, setSearchQuery] = useState(query || '');

    const results = useFlexSearch(searchQuery, data.localSearchPages.index, data.localSearchPages.store);
    //console.log('results: ', results);

    //let pages = results.filter(entry => entry.category === null)
    //console.log('pages: ', pages);

    return (
        <div className="page">
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname="/" />
            <Header />
            <div className="layout banner_bg grey">
                <div className="banner__container relative">
                    <div className="absolute banner_img">
                        <GatsbyImage image={entry.frontmatter.image.childImageSharp.gatsbyImageData} alt={entry.frontmatter.section} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
                    </div>
                    <div className="banner relative">
                        <h2>{entry.frontmatter.section}</h2>
                    </div>
                </div>
            </div>
            <main className="layout grey">
                <div className="search_container"> 
                    <div className="search_results">
                        <h1>You have searched for: {searchQuery}</h1>
                        <div className="search_results_pages">
                            {results.length > 0 ?
                                <div>
                                {results.map((entry, i) => (
                                    <div key={`page_${i}`}>
                                        <h3><Link to={entry.slug}>{entry.heading}</Link></h3>
                                        <p>{entry.excerpt} <Link to={entry.slug}>Read more</Link></p>
                                    </div>
                                ))}
                                </div>
                                : 'No search results found'
                            }
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export const query = graphql`
    query($slug: String!) {
        localSearchPages {
            store
            index
        }
        markdownRemark( fields: { slug: { eq: $slug } }) {
            frontmatter {
                section
                title
                page_title
                meta_description
                heading
                image {
                    childImageSharp {
                        gatsbyImageData(
                            width: 800, 
                            placeholder: NONE
                        )
                    }
                }
            }
            fields {
                slug
            }
            html
        }
    }
`

export default SearchResults;